import { useEffect,useState } from "react";
import {getAllArticlesForAdmin} from '../../utils/APIUtils'
import AdminActionOnArticle from "./AdminActionOnArticle";

const Admin = () => {
    const [articles,setArticles]=useState([]);

    const loadAllArticleListForAdmin = () => {
    getAllArticlesForAdmin().then((response) => {
      setArticles(response);
    });
  };

  const refreshArticles = () => {
    loadAllArticleListForAdmin();
  }

    useEffect(function () {
        loadAllArticleListForAdmin();
      },[]);
    return <>

    <table className="table">

       <thead>
    <tr>

      <th scope="col">ArticleId</th>
      <th scope="col">ArticleHeader</th>
      <th scope="col">published</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
     {
            
            articles.map(article => <AdminActionOnArticle refreshArticles={refreshArticles} key={article.id} article={article} />)
        }
   
    </tbody>
                

    </table>

    <div>
       
    </div>
    </>
}



const AdminActionHeader = () => {
    return <>
    
    </>
}
export default Admin;