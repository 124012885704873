// RightSidePage.js
import React, { useEffect, useState } from 'react';
import './RightSidePage.css';
import closeIcon from './icons8-close.svg'
import DropdownTopicStatus from './DropDownTopicStatus';

// import RightSidePage from './RightSidePage';

const RightSidePage = ({ onClose ,currentTopic}) => {


  const [currentArticleStatus,setCurrentArticleStatus] = useState('Pending');
  // useEffect(()=> {
    // here set current article status
  // },[currentArticleStatus]);

  const handleOptionSelect = (selectedKey) => {
    console.log("Selected Key:", selectedKey);
    setCurrentArticleStatus(selectedKey);
    // You can perform additional actions based on the selected key
  };

  const options = ["Pending", "In Progress", "Skip"];
  const defaultSelected = "In Progress";
  const selectName = "Update status"; 

  return (
  <div className="right-side-page-modal">
      <div>
        <div className="button-bar">
          <button className="status-button">{currentArticleStatus}</button>
          <div className="dropdown-container">
            <DropdownTopicStatus
              options={options}
              onSelect={handleOptionSelect}
              defaultSelected={defaultSelected}
              name={selectName}
            />
          </div>
          <button className="close-button" onClick={onClose}>
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
        
        <div>

          <div>
            <h1>{currentTopic}</h1>
          </div>
          {/* <div class="container" dangerouslySetInnerHTML={ { __html: `<h1 class="PlaygroundEditorTheme__h1"><span>Hosting</span></h1><p class="PlaygroundEditorTheme__paragraph" dir="ltr" style="text-align: start;"><span>Web hosting is an online service that allows you to publish your website files onto the internet. So, anyone who has access to the internet has access to your website.</span></p><p class="PlaygroundEditorTheme__paragraph" dir="ltr" style="text-align: start;"><span>Visit the following resources to learn more:</span></p><ul class="PlaygroundEditorTheme__ul"><li value="1" class="PlaygroundEditorTheme__listItem"><a href="https://www.youtube.com/watch?v=htbY9-yggB0" target="_blank" rel="noopener noreferrer nofollow" class="PlaygroundEditorTheme__link"><span>What Is Web Hosting? Explained</span></a></li><li value="2" class="PlaygroundEditorTheme__listItem"><a href="https://www.youtube.com/watch?v=AXVZYzw8geg" target="_blank" rel="noopener noreferrer nofollow" class="PlaygroundEditorTheme__link"><span>Different Types of Web Hosting Explained</span></a></li><li value="3" class="PlaygroundEditorTheme__listItem"><a href="https://www.youtube.com/watch?v=Kx_1NYYJS7Q" target="_blank" rel="noopener noreferrer nofollow" class="PlaygroundEditorTheme__link"><span>Where to Host a Fullstack Project on a Budget</span></a></li></ul>`}} /> */}
          <div className="container" dangerouslySetInnerHTML={ { __html: `<h1 class="PlaygroundEditorTheme__h1"><span>Hosting</span></h1><p class="PlaygroundEditorTheme__paragraph" dir="ltr" style="text-align: start;"><span>Web hosting is an online service that allows you to publish your website files onto the internet. So, anyone who has access to the internet has access to your website.</span></p><p class="PlaygroundEditorTheme__paragraph" dir="ltr" style="text-align: start;"><span>Visit the following resources to learn more:</span></p><ul class="PlaygroundEditorTheme__ul"><li value="1" class="PlaygroundEditorTheme__listItem"><a href="https://www.youtube.com/watch?v=htbY9-yggB0" target="_blank" rel="noopener noreferrer nofollow" class="PlaygroundEditorTheme__link"><span>What Is Web Hosting? Explained</span></a></li><li value="2" class="PlaygroundEditorTheme__listItem"><a href="https://www.youtube.com/watch?v=AXVZYzw8geg" target="_blank" rel="noopener noreferrer nofollow" class="PlaygroundEditorTheme__link"><span>Different Types of Web Hosting Explained</span></a></li><li value="3" class="PlaygroundEditorTheme__listItem"><a href="https://www.youtube.com/watch?v=Kx_1NYYJS7Q" target="_blank" rel="noopener noreferrer nofollow" class="PlaygroundEditorTheme__link"><span>Where to Host a Fullstack Project on a Budget</span></a></li></ul>`}} />

        </div>

      </div>
    </div>
  );
};


export default RightSidePage;
