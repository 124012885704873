import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from '../footer/Footer';

const Navbar = ({ authenticated, handleLogout }) => {
  return (
    <>

  <nav className="navbar navbar-expand-sm">
  <div className="container-fluid">
    <a className="navbar-brand" href="/"><img src="/csguidetech-logo.webp" width={192} alt="" height={60}></img></a>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink to="/lld" className={"nav-link"}>
          Low Level Design
        </NavLink>
        </li>

        <li className="nav-item">
          <NavLink to="/designpatterns" className={"nav-link"}>
          Design Patterns
        </NavLink>
        </li>

        <li className="nav-item">
          <NavLink to="/articles" className={"nav-link"}>
          Articles
        </NavLink>
        </li>


        <li className="nav-item">
          
          {authenticated ? (
          <div className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown">Account</a>
            <ul className="dropdown-menu">
            <li>
              <NavLink to="/profile" className={"dropdown-item"}>
                Profile
              </NavLink>
            </li>
            
            <li><a className="dropdown-item" href= "/" onClick={() => handleLogout()} >Logout</a></li>
          </ul>
          </div>
        ) : (
          <div className="nav-item">
            <NavLink to="/login" className={"nav-link"}>
              Login
            </NavLink>
          </div>
        )}
        </li>




      </ul>
    </div>
  </div>
</nav>
    </>
  );
};

const AppHeader = ({ authenticated, handleLogout }) => {
  return (
    <div style={{paddingLeft:"2%",paddingRight:"2%"}}>
      <Navbar authenticated={authenticated} handleLogout={handleLogout} />
      <Outlet  />
       <br />
       <br />
      <Footer />
    </div>
  );
};

export default AppHeader;
