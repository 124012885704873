import { Link } from "react-router-dom";

const FlatArticle = ({ article }) => {
  return (
    <a   href={"/s/articles/"+((article.articleUrlShortName)?article.articleUrlShortName:article.id)}>
      <p>{article.articleHeader}</p>
      
    </a>
  );
};

export default FlatArticle;
