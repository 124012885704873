import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "./Login.css";
import {
  GOOGLE_AUTH_URL,
  FACEBOOK_AUTH_URL,
  GITHUB_AUTH_URL,
  ACCESS_TOKEN,
} from "../../constants";

import { login } from "../../utils/APIUtils";
import fbLogo from "../../img/fb-logo.png";
import googleLogo from "../../img/google-logo.png";
import githubLogo from "../../img/github-logo.png";

const Login = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.error) {
      setTimeout(() => {
        alert(location.state.error);
        navigate(location.pathname, {
          state: {},
          replace: true,
        });
      }, 100);
    }
  }, []);

  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <h1 className="login-title">Login</h1>
          <SocialLogin />
          <div className="or-separator">
            <span className="or-text">OR</span>
          </div>
          <LoginForm setAuthenticated={setAuthenticated} />
          <span className="signup-link">
            New user? <Link to="/signup">Sign up!</Link>
          </span>
        </div>
      </div>
    </>
  );
};

const LoginForm = ({ setAuthenticated }) => {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const handleInputChange = (e) => {
    const newLoginData = { ...loginData, [e.target.name]: e.target.value };
    setLoginData(newLoginData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const loginRequest = Object.assign({}, loginData);

    login(loginRequest)
      .then((response) => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        setAuthenticated(true);
        alert("You're successfully logged in!");

        navigate("/");
      })
      .catch((error) => {
        alert("Oops! Something went wrong. Please try again!");
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-item">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            value={loginData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-item">
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Password"
            value={loginData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-item">
          <button type="submit" className="btn btn-block btn-primary">
            Login
          </button>
        </div>
      </form>
    </>
  );
};

const SocialLogin = () => {

  return (
      <div className="social-login">
        <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
          <div className="d-flex align-items-center">
            <img src={googleLogo} alt="Google" className="google-logo" />
            <span className="ms-2">Log in with Google</span>
          </div>
        </a>
      </div>
    );

};

export default Login;
