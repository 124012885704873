import React,{ useEffect,useState } from 'react';

import Mermaid from "./Mermaid";
import example from "./example";
import ButtonWithCenteredText from './ButtonWithCenteredText';
import './TableWithLinks.css';

const RoadmapList = () => {

  const [tableData,setTableData] = useState([])

  useEffect(()=>{
      const data = [
        { id: 1,  link: '/roadmap/backend',"label":"Backend" },
        { id: 2, link: '/roadmap/frontend',"label":"Frontend" },
        { id: 3,  link: '/roadmap/fullstack',"label":"Fullstack" },
        { id: 4, link: '/roadmap/devops',"label":"DevOps" },
        { id: 5, link: '/roadmap/android',"label":"Android" },
        { id: 6,  link: '/roadmap/qa',"label":"QA" },
        { id: 7,  link: '/roadmap/postgressql',"label":"PostgreSQL" },
        { id: 8,  link: '/roadmap/bob',"label":"Blockchain" },
        { id: 9,  link: '/roadmap/softwarearchitect',"label":"Software Architect" },
        { id: 10,  link: '/roadmap/aspnetcore',"label":"ASP.NET Core" },
        { id: 11,  link: '/roadmap/cplusplus',"label":"C++" },
        { id: 12,  link: '/roadmap/flutter',"label":"Flutter" },
        { id: 13,  link: '/roadmap/security',"label":"Cyber Security" },
        { id: 14,  link: '/roadmap/uxdesign',"label":"UX Design" },
        { id: 15,  link: '/roadmap/reactnative',"label":"React Native" }
];

      setTableData(data);

  },[])

  return (
    <div className="container-sm" >
      <h2>Role based roadmaps</h2>
      <div className="button-grid">
        {tableData.map(item => (
          <ButtonWithCenteredText key={item.id} label={item.label} link={item.link} />
        ))}
      </div>
    </div>
  );

  //    return (
  //       <Mermaid chart={example} />
  // );
};

export default RoadmapList;