import {publishArticle,unPublishArticle,deleteArticle} from '../../utils/APIUtils';

const AdminActionOnArticle = ({article,refreshArticles}) =>{

    const publishArticleById = (articleId) => {
    publishArticle(articleId).then((response) => {
        console.log(response);
        refreshArticles();
    });
}

const unPublishArticleById = (articleId) => {
    unPublishArticle(articleId).then((response) => {
        console.log(response);
        refreshArticles();
    });
}

const deleteArticleById = (articleId) => {
    deleteArticle(articleId).then((response) => {
        console.log(response);
        refreshArticles();
    });
}



    return <>
    <tr>
      <th scope="row">{article.id}</th>
      <td><a target="_blank" rel="noreferrer" href={"/articles/"+article.id}>{article.articleHeader}</a></td>
      <td>{article.published?'yes':'no'}</td>
      <td>
        <div className="dropdown">
        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
           Action
        </button>
        <ul className="dropdown-menu" aria-labelledby="Action">
            <li><div className="dropdown-item" id={article.id} onClick={e=> publishArticleById(e.target.id)}>Publish</div></li>
            <li><div className="dropdown-item" id={article.id} onClick={e => unPublishArticleById(e.target.id)}>UnPublish</div></li>
            <li><div className="dropdown-item" id={article.id} onClick={e => deleteArticleById(e.target.id)}>delete</div></li>
        </ul>
    </div>
      </td>
    </tr>
    </>
}

export default AdminActionOnArticle;