import { Link } from "react-router-dom";

const Article = ({ article }) => {
  return (
    <a  className={"card col-md-4"}  href={"/cg/articles/"+((article.articleUrlShortName)?article.articleUrlShortName:article.id)} alt="" style={{border:0,textDecoration:'none',paddingLeft:'1%',paddingRight:"1%",paddingBottom:"1%",paddingTop:"1%",display:'flex'}}>
      <div className="mx-auto" style={{'backgroundColor': 'dark'}}>
        <img className="card-img-top rounded img-fluid  d-block" src={article.articleImageUrl} alt="" style={{aspectRatio:'16 / 9'}}/>
      <div alt="article header" style={{ display: "block", textAlign: "center", marginTop: "1%",textDecoration:"" }}>{article.articleHeader}</div>
      </div>
      
    </a>
  );
};

export default Article;
