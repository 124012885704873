import { useEffect } from "react";
import { useState } from "react";
import Article from "./Article";
import { getArticlesBySectionName } from "../../utils/APIUtils";
import FlatArticle from "./FlatArticle";

const CustomCuratedArticleList = ({path}) => {
  const [articles, setArticles] = useState([]);
  const loadArticleList = () => {
    getArticlesBySectionName('lld').then((response) => {
      setArticles(response.data);
    });
  };
  useEffect(() => {
    document.title='CSGuide.Tech: Learn Computer Science, Inside and Out'
    loadArticleList();
  }, []);
  


  return (
    <div className={"row"} style={{paddingTop:"1%",backgroundColor:"dark"}}>
        <h2 style={{paddingTop:"3%",paddingBottom:"1%"}}>{path == 'lld'? 'Low Level Design': 'Design Patterns'}</h2>
      {articles.map((article) => (
        <FlatArticle key={article.id} article={article} />
      ))}
    </div>
  );

};

export default CustomCuratedArticleList;
