import { useEffect } from "react";
import { useState } from "react";
import Article from "./Article";
import { getArticleList } from "../../utils/APIUtils";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const loadArticleList = () => {
    getArticleList().then((response) => {
      setArticles(response.data);
    });
  };
  useEffect(() => {
    document.title='CSGuide.Tech: Learn Computer Science, Inside and Out'
    loadArticleList();
  }, []);
  
  return (
    <div className={"row"} style={{paddingTop:"1%",backgroundColor:"dark"}}>
      {articles.map((article) => (
        <Article key={article.id} article={article} />
      ))}
    </div>
  );
};

export default ArticleList;
