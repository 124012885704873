
const ArtilceEditor = () => {

    const parseHtmlFunction= (event) => {
        
        let htmlToParse='';

        if(document.getElementById("articleEditorHTMLTextArea")){
            htmlToParse=htmlToParse+document.getElementById("articleEditorHTMLTextArea").value;
        }

        if(document.getElementById("articleEditorCSSTextArea")){
            htmlToParse=htmlToParse +'<style>'+document.getElementById("articleEditorCSSTextArea").value+'</style>';
        }
        if(document.getElementById("articleEditorJSTextArea")){
            htmlToParse=htmlToParse+'<script>'+document.getElementById("articleEditorJSTextArea").value+'</script>';
        }
        
        document.getElementById("articleEditorOutput").srcdoc=htmlToParse;
        console.log(event);
    } 
    //"i.srcdoc=h.value+'<style>'+c.value+
     //       '</style><script>'+j.value+'<\/script>'"

    return (
        <>
        <div  onInput={event => {parseHtmlFunction(event)}} style={{width:"100%",height:"90%",display:"block"}}>


    <textarea placeholder="HTML" id="articleEditorHTMLTextArea" style={{width:"33.33%",fontSize:18}}></textarea>
    <textarea placeholder="CSS" id="articleEditorCSSTextArea"></textarea>
    <textarea placeholder="articleEditorJSTextArea" id="j"></textarea>
    <iframe title="output" id="articleEditorOutput" style={{width:"100%",height:"50%"}}></iframe>
    </div>
    </>
    )
}

export default ArtilceEditor;