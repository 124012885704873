import React from 'react';
import './ButtonWithCenteredText.css'; // Import your CSS file for styling

const ButtonWithCenteredText = ({ label, link }) => {
  return (
    <button className="centered-button">
      <a href={link} rel="noopener noreferrer">
        {label}
      </a>
    </button>
  );
};

export default ButtonWithCenteredText;
