import { Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../../constants";

const ProtectedRoute = ({ children, currentUser }) => {
  let accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return children;
};
export default ProtectedRoute;
