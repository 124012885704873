import React, { useState, useEffect } from "react";

const DropdownTopicStatus = ({ options, onSelect, defaultSelected, name }) => {
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setSelectedOption(defaultSelected);
  }, [defaultSelected]);

  const handleSelect = (event) => {
    const selectedKey = event.target.value;
    setSelectedOption(selectedKey);
    onSelect(selectedKey);
  };

  return (
    <div>
      <select name={name} value={selectedOption} onChange={handleSelect}>
        <option defaultValue="">Update status</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownTopicStatus;
