import { useEffect, useState } from 'react';
import {callback} from'./Helpers.js'
import Mermaid from "./Mermaid";
import example from "./example";
import RightSidePage from './RightSidePage.js';

const Roadmap = () => {



    const [isRightPageOpen,setIsRightPageOpen] = useState(false);

    const [currentTopic,serCurrentTopic] = useState('');


    const openRightPage = (topic) => {
        console.log(topic);
        serCurrentTopic(topic);
        setIsRightPageOpen(true);
    }

    const closeRightPage = () => {
        setIsRightPageOpen(false);
    }


    useEffect(()=>{

       
     let svgLists = document.querySelectorAll('svg');

      try{
          for (let svgIndex in svgLists) {
        let svg = svgLists[svgIndex];
        
        if(svg){
          let gTags = svg.querySelectorAll('.clickable-group');
        
        for (let gTagIndex in gTags) {
          let gTag = gTags[gTagIndex];
          if(gTag){
            gTag.addEventListener("click", (e) => { 
              
              const parentID = gTag.id;
              console.log(`Parent ID: ${parentID}`);


              openRightPage(parentID); 
            });
             gTag.addEventListener('mouseover', () => {
            console.log("mouseover case");
            // Modify styles or perform actions here
            let rect = gTag.getElementsByTagName('rect')[0];
            rect.style.setProperty('fill','brown');
            rect.style.setProperty('cursor','pointer');
            //gTag.style = 'fill:#66cc00';
          });

          gTag.addEventListener('mouseout', () => {
            // Reset styles or actions here
            console.log("mouseout case");
            let rect = gTag.getElementsByTagName('rect')[0];
            rect.style.setProperty('fill','#ffcc00');
            rect.style.setProperty('cursor','default');
          });
          }
        }
        }
      }
      }catch(e){
        console.log(e);
      }

       // let gTags=document.querySelector("g");

      //  gTags.addEventListener("click", (e) => {openRightPage(e);})

        

        window.callback=function callback() {
            console.log("rightPage opened");
            openRightPage(true);
          };
    },[])

     return (
        <div className="container">
  <span style={{"display": "flex", "flexDirection": "row"}}>
    {/* <Mermaid chart={`classDiagram
      note for Duck "can fly\ncan swim\ncan dive\ncan help in debugging"`} />
    <Mermaid chart={`

        flowchart LR
        A-->B
        B-->C
        C-->D
        click A callback "."
        click B "https://www.github.com" "."
        click C call callback() "."
        click D href "https://www.github.com" "."
    
    `} /> */}


     <div className="container" dangerouslySetInnerHTML={ { __html: `
            

            <svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg1"
   inkscape:export-filename="bitmap.svg"
   inkscape:export-xdpi="96"
   inkscape:export-ydpi="96"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <sodipodi:namedview
     id="namedview1"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm" />
  <defs
     id="defs1" />
 
    <rect
       style="fill:#ffcc00;fill-opacity:1;stroke-width:0.264583"
       id="rect4"
       width="38.247139"
       height="21.823261"
       x="32.231976"
       y="37.953461" />
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:3.175px;font-family:AnjaliOldLipi;-inkscape-font-specification:AnjaliOldLipi;fill:#ffcc00;fill-opacity:1;stroke-width:0.264583"
       x="37.005661"
       y="45.768192"
       id="text5"><tspan
         sodipodi:role="line"
         id="tspan5"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:AnjaliOldLipi;-inkscape-font-specification:AnjaliOldLipi;stroke-width:0.264583"
         x="37.005661"
         y="45.768192">hhhh</tspan></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.35px;font-family:'DejaVu Sans Mono';-inkscape-font-specification:'DejaVu Sans Mono';fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
       x="39.492741"
       y="49.405128"
       id="text10"><tspan
         sodipodi:role="line"
         id="tspan10"
         style="stroke-width:0.264583"
         x="39.492741"
         y="49.405128">Backend</tspan></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.35px;font-family:'DejaVu Sans Mono';-inkscape-font-specification:'DejaVu Sans Mono';fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
       x="30.332506"
       y="55.831375"
       id="text12"><tspan
         sodipodi:role="line"
         id="tspan12"
         style="stroke-width:0.264583"
         x="30.332506"
         y="55.831375"> Developer</tspan></text>
    <circle
       id="path17"
       style="fill:#ffcc00;stroke:#000000;stroke-width:0.264583"
       cx="47.325039"
       cy="67.4674"
       r="0.048480526" />
    <circle
       id="path18"
       style="fill:#ffcc00;stroke:#000000;stroke-width:0.264583"
       cx="61.856228"
       cy="63.099682"
       r="0.048480526" />
    <path
       style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
       d="m 70.865565,58.561686 c 2.031511,2.055606 3.72006,4.331065 5.425527,6.605032 0.307761,0.41035 0.75315,0.458269 1.179505,0.884624 1.279959,1.279959 2.630001,2.641838 3.7622,4.057081 0.647714,0.809638 0.957162,2.800096 1.587971,3.62019 0.852485,1.108287 1.294207,2.049175 1.755609,3.432718 1.3e-5,4e-5 0.589737,0.589716 0.589764,0.589753 0.337386,0.505889 1.746742,4.183645 1.746742,4.262702 0,0.78902 0.650184,1.557452 1.000321,2.42419 0.212756,0.526669 -0.26537,1.419267 0,1.917866 0.141009,0.264946 0.580824,0.283507 1.028041,0.283507"
       id="path20" />
    <path
       style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
       d="m 60.515507,28.146306 c 0.905447,0.94588 1.805916,1.896549 2.71634,2.83764 0.891579,0.92161 1.738905,2.16993 2.948752,2.653877 0.609952,0.243983 1.761738,0.448852 2.240806,0.810757 0.09969,0.07531 -0.07305,0.505201 0,0.615945 0.248444,0.376653 0.664276,0.760891 0.862629,1.157512 0.43629,0.872379 -0.601456,2.166975 0.70626,2.166975"
       id="path21" />
    <path
       style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
       d="m 71.088204,38.652457 c 8.002667,-0.364265 16.230872,-0.666945 24.148261,-2.064128 0.704689,-0.124357 1.356876,-0.480936 2.064131,-0.58974 0.570527,-0.08777 4.254144,0.289065 4.632564,0 0.64372,-0.491731 2.77945,-1.802003 3.47873,-1.802003"
       id="path22" />
    <g
       id="python" class="clickable-group">
      <rect
         style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
         id="rect20"
         width="42.931469"
         height="17.722795"
         x="18.585449"
         y="11.350633" />
      <text
         xml:space="preserve"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.35px;font-family:'DejaVu Sans Mono';-inkscape-font-specification:'DejaVu Sans Mono';fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
         x="26.818651"
         y="18.316751"
         id="text23"><tspan
           sodipodi:role="line"
           id="tspan23"
           style="stroke-width:0.264583"
           x="26.818651"
           y="18.316751">Python</tspan></text>
    </g>
    <g
       id="java" class="clickable-group" >
      <rect
         style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
         id="rect19"
         width="49.830936"
         height="24.410299"
         x="104.90055"
         y="32.775635" />
      <text
         xml:space="preserve"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.35px;font-family:'DejaVu Sans Mono';-inkscape-font-specification:'DejaVu Sans Mono';fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
         x="123.05788"
         y="42.49765"
         id="text24"><tspan
           sodipodi:role="line"
           id="tspan24"
           style="stroke-width:0.264583"
           x="123.05788"
           y="42.49765">Java</tspan></text>
    </g>
    <g
       id="javascript" class="clickable-group" >
      <rect
         style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
         id="rect18"
         width="69.751862"
         height="18.139114"
         x="44.154827"
         y="85.495949"
         ry="0.85906523" />
      <text
         xml:space="preserve"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.35px;font-family:'DejaVu Sans Mono';-inkscape-font-specification:'DejaVu Sans Mono';fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:0.264583;stroke-opacity:1"
         x="59.971287"
         y="95.156364"
         id="text25"><tspan
           sodipodi:role="line"
           id="tspan25"
           style="stroke-width:0.264583"
           x="59.971287"
           y="95.156364">Javascript</tspan></text>
    </g>
  
</svg>



          `}} />


    {isRightPageOpen && <RightSidePage onClose={closeRightPage} currentTopic={currentTopic} />}
  </span>
</div>
  );
};

export default Roadmap;