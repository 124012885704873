import { React, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getCurrentUser } from "../../utils/APIUtils";
import { ACCESS_TOKEN } from "../../constants";
import LoadingIndicator from "../shared/LoadingIndicator";
import AppHeader from "../appHeader";

import Login from "../login";
import ProtectedRoute from "../protectedRoute/ProtectedRoute";
import Error from "../error";
import Home from "../home";
import OAuth2RedirectHandler from "../oauth2/OAuth2RedirectHandler";
import Profile from "../profile/Profile";
import Signup from "../signup/Signup";
import ArticleList from "../articles/ArticleList";
import DetailedArticle from "../articles/DetailedArticle";
import ArtilceEditor from "../editor/ArticleEditor";
import Admin from '../admin/Admin';
import RoadmapList from "../roadmap/RoadmapList";
import Roadmap from '../roadmap/Roadmap';
import CustomCuratedArticleList from "../articles/CustomCuratedArticleList";


const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    email: "",
    imageUrl: "",
    name: "",
  });
  const [loading, setLoading] = useState(true);

  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        setCurrentUser(response);
        setAuthenticated(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    setCurrentUser(null);
    setAuthenticated(false);
  };

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AppHeader
              authenticated={authenticated}
              handleLogout={handleLogout}
            />
          }
        >
          {/* <Route index element={<Home />} /> */}
          <Route index element={<ArticleList />} />
          

          <Route
            path="login"
            element={<Login setAuthenticated={setAuthenticated}></Login>}
          />
          <Route
            path="signup"
            element={<Signup authenticated={authenticated}></Signup>}
          />
          <Route
            path="/articles"
            element={<ArticleList authenticated={authenticated}></ArticleList>}
          />

          <Route
            path="/lld"
            element={<CustomCuratedArticleList path={"lld"} authenticated={authenticated}></CustomCuratedArticleList>}
          />

          <Route
            path="/designpatterns"
            element={<CustomCuratedArticleList path={"designpatterns"} authenticated={authenticated}></CustomCuratedArticleList>}
          />

          <Route
            path="/articles/:articleId"
            element={<DetailedArticle authenticated={authenticated}></DetailedArticle>}
          />

          <Route
            path="/articleEditor"
            element={<ArtilceEditor authenticated={authenticated}></ArtilceEditor>}
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute currentUser={currentUser}>
                <Profile currentUser={currentUser} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute currentUser={currentUser}>
                <Admin currentUser={currentUser} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/oauth2/redirect"
            element={
              <OAuth2RedirectHandler
                setCurrentUser={setCurrentUser}
                setAuthenticated={setAuthenticated}
              />
            }
          />

          <Route
            path="/roadmap/:roadmapName"
            element={<Roadmap authenticated={authenticated}></Roadmap>}
          />

          <Route
            path="/roadmap"
            element={<RoadmapList ></RoadmapList>}
          />

          <Route path="/seo" element={() => null} />

          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
