import { API_BASE_URL, ACCESS_TOKEN } from "../constants";
import axios from "axios";

const request = (options) => {
  const headers = new Headers({
    "Content-Type" : "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers,"credentials": "include", };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export const fetUserData = async () => {
  try {
    const { data } = await axios(API_BASE_URL + "/user/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
    });
    return data;
  } catch (error) {
    console.log(error.response);
  }
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/me",
    method: "GET",
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/login",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function getArticleList() {
  return request({
    url: API_BASE_URL + "/articles",
    method: "GET",
  });
}

export function getArticlesBySectionName(sectionName) {
  return request({
    url: API_BASE_URL + "/articles/sections/"+sectionName,
    method: "GET",
  });
}

export function getDetailedArticle(articleId) {
  return request({
    url: API_BASE_URL + "/articles/"+articleId,
    method: "GET",
  });
}


export function getAllArticlesForAdmin() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/articles",
    method: "GET",
  });
}

export function publishArticle(articleId) { 

  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/articles/"+articleId+':publish',
    method: "POST",
  });
}

export function unPublishArticle(articleId) { 

  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/articles/"+articleId+':unPublish',
    method: "POST",
  });
}

export function deleteArticle(articleId) { 

  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/articles/"+articleId+':delete',
    method: "POST",
  });
}