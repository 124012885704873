import { ACCESS_TOKEN } from "../../constants";
import { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { getCurrentUser } from "../../utils/APIUtils";
//   console.log("Inside oauth2");
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [searchParams, setSearchParams] = useSearchParams();
//   console.log(searchParams);
//   console.log(location);
//   console.log(useParams());
//   console.log(token, error);

//   const token = getUrlParameter("token");
//   const error = getUrlParameter("error");
const OAuth2RedirectHandler = ({ setCurrentUser, setAuthenticated }) => {
  const [rData, setRData] = useState({ token: "", error: "" });
  const navigate = useNavigate();
  const location = useLocation();

  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        setCurrentUser(response);
        setAuthenticated(true);
        navigate("/profile", { state: { from: location } });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUrlParameter = (location, name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  useEffect(() => {
    const token = getUrlParameter(location, "token");
    const error = getUrlParameter(location, "error");
    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);
      localStorage.setItem("accessToken1", "set");
      loadCurrentlyLoggedInUser();
    } else {
      navigate("/login", {
        state: {
          from: location,
          error: error,
        },
      });
    }
  }, []);
};

export default OAuth2RedirectHandler;
