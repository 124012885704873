import { useState } from "react";
import "./Profile.css";

const Profile = ({ currentUser }) => {
  const { imageUrl = "", name = "", email = "" } = currentUser;
  return (
    <div className="profile-container">
      <div className="container">
        <div className="profile-info">
          <div className="profile-avatar">
            {!!imageUrl ? (
              <img src={currentUser.imageUrl} alt={name} />
            ) : (
              <div className="text-avatar">
                <span>{!!name && name[0]}</span>
              </div>
            )}
          </div>
          <div className="profile-name">
            <h2>{name}</h2>
            <p className="profile-email">{email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
