import { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { GITHUB_AUTH_URL, GOOGLE_AUTH_URL } from "../../constants";
import { signup } from "../../utils/APIUtils";
import googleLogo from "../../img/google-logo.png";
import githubLogo from "../../img/github-logo.png";

import "./signup.css";

const Signup = ({ authenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authenticated) {
      navigate("/", {
        state: {
          from: location,
        },
      });
    }
  }, []);

  return <>{!authenticated && <SignUpContainer />}</>;
};

//const SignUpContainer1 = () => {
//
//    return <>
//        <div class="container">
//                <div class="row justify-content-center">
//                    <div class="col-md-6">
//                        <h2 class="my-4 text-center">Signup</h2>
//                        <form>
//                            <div class="mb-3">
//                                <label for="name" class="form-label">Name</label>
//                                <input type="text" class="form-control" id="name" placeholder="Your Name">
//                            </div>
//                            <div class="mb-3">
//                                <label for="email" class="form-label">Email</label>
//                                <input type="email" class="form-control" id="email" placeholder="you@example.com">
//                            </div>
//                            <div class="mb-3">
//                                <label for="password" class="form-label">Password</label>
//                                <input type="password" class="form-control" id="password" placeholder="Password">
//                            </div>
//                            <button type="submit" class="btn btn-primary btn-block">Signup</button>
//                        </form>
//                        <div class="text-center my-4">OR</div>
//                        <button class="btn btn-danger btn-block">Signup with Google</button>
//                    </div>
//                </div>
//            </div>
//    </>
//
//}

const SignUpContainer = () => {
  return (
    <div className="signup-container">
      <div className="signup-content">
        <h1 className="signup-title">Signup to CsGuide.Tech</h1>
        <SocialSignup />
        <div className="or-separator">
          <span className="or-text">OR</span>
        </div>
        <SignupForm />
        <span className="login-link">
          Already have an account? <NavLink to="/login">Login!</NavLink>
        </span>
      </div>
    </div>
  );
};

const SocialSignup = () => {

  return (

        <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
          <div className="d-flex align-items-center">
            <img src={googleLogo} alt="Google" className="google-logo" />
            <span className="ms-2">Sign up with Google</span>
          </div>
        </a>

    );

};

const SignupForm = () => {
  const navigate = useNavigate();

  const [signUpData, setSignUpData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const signUpRequest = Object.assign({}, signUpData);

    signup(signUpRequest)
      .then((response) => {
        alert("You're successfully registered. Please login to continue!");
        navigate("/login");
      })
      .catch((error) => {
        alert(
          (error && error.message) ||
            "Oops! Something went wrong. Please try again!"
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-item">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Name"
            value={signUpData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-item">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            value={signUpData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-item">
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Password"
            value={signUpData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-item">
          <button type="submit" className="btn btn-block btn-primary">
            Sign Up
          </button>
        </div>
      </form>
    </>
  );
};

export default Signup;
