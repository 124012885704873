import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import { getDetailedArticle } from "../../utils/APIUtils";
import './detailedArticle.css';

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

const DetailedArticle = () => {
    let {articleId} = useParams();
    const [detailedArticle,setDetailedArticle] =useState('loading')
    
    const loadArticleList = () => {
    getDetailedArticle(articleId).then((response) => {
      if(response.data.articleMetaDescription){
        // Set the meta description in the HTML document.
        document.querySelector('meta[name="description"]').content = response.data.articleMetaDescription;
      }

      if(response.data.articleHeader){
        document.title=response.data.articleHeader;
      }
      setDetailedArticle(htmlDecode(response.data.htmlContent));
    });
  };
  useEffect(() => {
    loadArticleList();
  }, []);

    return <>
     <div class="container" dangerouslySetInnerHTML={ { __html: detailedArticle}} />
    </>

}

export default DetailedArticle;